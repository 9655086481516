
import { Options, Vue } from 'vue-class-component';
import AppTile from './components/apps/AppTile.vue';
import TopBar from './components/common/TopBar.vue';
import { ProvideReactive } from 'vue-property-decorator';
import HelpWindow from '@/components/common/HelpWindow.vue';

@Options({
    components: {
        HelpWindow,
        TopBar,
        AppTile,
    },
})
export default class App extends Vue {
    protected apps = [
        {
            title: 'Expired Assets',
            description: 'Automatically update assets when they are about to expire.',
            installed: false,
        },
        {
            title: 'Some other app',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse leo sapien',
            installed: true,
        },
    ];

    @ProvideReactive()
    public now = Date.now();


    protected help = {
        show: false,
        data: null,
    };

    showHelp(data: any = {}): void {
        this.help.show = true;
        this.help.data = data;
    }
}
