
import { Options, Vue } from 'vue-class-component';
import LegacyModal from '@/components/common/LegacyModal.vue';
import api from '@/shared/api';

interface User {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    lastNamePrefix: string;
}

@Options({
    components: {
        Modal: LegacyModal,
    },
})
export default class AddUser extends Vue {
    private newUserEmail = '';
    private newUserFirstName = '';
    private newUserLastName = '';
    private newUserLastNamePrefix = '';

    //Create

    private async createUser() {
        const user: User = await api.createUser(this.newUserFirstName, this.newUserLastName, this.newUserEmail, this.newUserLastNamePrefix);
        await api.createUser(this.newUserFirstName, this.newUserLastName, this.newUserEmail, this.newUserLastNamePrefix);

        try {
            await api.authRecoverInEmail(user.email);
        } catch (error) {
            console.error(error);
        }

        this.newUserFirstName = '';
        this.newUserLastName = '';
        this.newUserEmail = '';
        this.newUserLastNamePrefix = '';
        this.$router.push({ name: 'manage-users' });
    }

    protected cancelCreateUser() {
        this.newUserFirstName = '';
        this.newUserLastName = '';
        this.newUserEmail = '';
        this.newUserLastNamePrefix = '';
        this.$router.push({ name: 'manage-users' });
    }
}
