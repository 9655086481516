import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6ed942d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-content" }
const _hoisted_2 = { class: "modal-header" }
const _hoisted_3 = { class: "modal-title" }
const _hoisted_4 = {
  key: 0,
  class: "modal-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal",
    tabindex: "-1",
    role: "dialog",
    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('cancel')))
  }, [
    _createElementVNode("div", {
      class: "modal-dialog",
      role: "document",
      onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "header", {}, () => [
            _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.title), 1)
          ], true),
          (_ctx.cancelButton !== false)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                type: "button",
                class: "btn-close",
                "data-dismiss": "modal",
                "aria-label": "Close",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cancel')))
              }))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", {
          class: "modal-body",
          style: _normalizeStyle({ minWidth: _ctx.minWidth ? _ctx.minWidth : '500px', maxWidth: _ctx.maxWidth ? _ctx.maxWidth : 'none' })
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 4),
        (_ctx.showFooter)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "buttons", {}, () => [
                (_ctx.cancelButton !== false)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      type: "button",
                      class: "btn btn-secondary",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('cancel')))
                    }, " Cancel "))
                  : _createCommentVNode("", true),
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-primary",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('confirm')))
                }, "OK")
              ], true)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}