
import { Options, Vue } from 'vue-class-component';
import api from '@/shared/api';
import session from '@/shared/session';
import Modal from '@/components/common/Modal.vue';

@Options({
    components: { Modal },
})
export default class AppOverview extends Vue {
    protected error = '';
    protected email = '';
    protected password = '';

    async login() {
        this.error = '';
        try {
            const data = await api.authLogin(this.email, this.password);
            console.log('login result', data);
            if (data.error) {
                this.error = data.error;
            } else {
                await session.setTokens(data);
                await this.$router.push({ name: 'apps-store' });
            }
        } catch (error) {
            console.error(error);
            if (String(error).includes('401')) {
                this.error = "Invalid Username or Password 😔"
            }
            else {
                this.error = String(error).toString()
            }
            this.email = '';
            this.password = '';
        }
    }
}
