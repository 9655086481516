
import { Options, Vue } from 'vue-class-component';
import LegacyModal from '@/components/common/LegacyModal.vue';
import api from '@/shared/api';

interface BusinessUnit {
    id: string;
    name: string;
    parent: BusinessUnit | null;
}

@Options({
    components: {
        Modal: LegacyModal,
    },
})
export default class AddBusinessUnits extends Vue {
    private newName = '';
    private newParent: BusinessUnit | null = null;
    private businessUnits: BusinessUnit[] = [];
    private businessUnitsLoaded = false;
    private parent_id: string = '';

    // Get
    private async loadBusinessUnits() {
        try {
            const businessUnits = await api.getBusinessUnits();
            this.businessUnits = businessUnits;
            this.businessUnitsLoaded = true;
        } catch (error) {
            console.error('Error loading business units:', error);
        }
    }

    // Create
    private async addBusinessUnit() {
        if (!this.newName) {
            console.log('BusinessUnit name is required');
            return;
        }

        let parent_id: string | null = null;
        if (this.newParent && this.newParent.id !== '0') {
            parent_id = String(this.newParent.id);
        }

        try {
            await api.addBusinessUnit(this.newName, parent_id as string);
            console.log('BusinessUnit created successfully');
        } catch (error) {
            console.error('Error creating BusinessUnit:', error);
        }

        this.newName = '';
        this.newParent = null;
        this.$router.push({ name: 'manage-businessunits' });
    }

    private cancelAddBusinessUnit() {
        this.newName = '';
        this.newParent = null;
        this.$router.push({ name: 'manage-businessunits' });
    }

    async mounted(): Promise<void> {
        await this.loadBusinessUnits();
    }
}
