
import { Options, Vue } from 'vue-class-component';
import LegacyModal from "@/components/common/LegacyModal.vue";
import api from "@/shared/api";

type Env = {
    name: string;
    key: string;
    redirect_uri: string;
};

@Options({
    components: {Modal: LegacyModal}
})
export default class PurchaseRegisterPaymentCallback extends Vue {
    protected ready = false;
    protected error = '';
    protected name = '';
    protected email = '';
    protected businessUnitName = '';
    protected newUser: boolean = true;
    protected passwordToken = '';
    protected passwordSaved = false;
    protected password = '';
    protected confirmPassword = '';
    protected signupId = '';
    protected signupCompleted = false;

    async created() {
        this.signupId = this.$route.query.signupId as string;
        await this.waitForPaymentComplete();
    }

    async waitForPaymentComplete() {
        const status = await api.getSignupStatus(this.signupId);
        if (status.paymentStatus === 'paid') {
            const response = await api.saveSignupPaymentComplete(this.signupId);

            console.log('register result', response);
            this.businessUnitName = response.businessUnitName;
            this.email = response.sprinklrUser.email;
            this.name = response.sprinklrUser.name;
            this.passwordToken = response.passwordToken;
            this.newUser = response.newUser;
            // this.$router.push({ name: 'external-systems' });
            this.ready = true;

            this.waitForSignupComplete();
        } else {
            setTimeout(() => {
                this.waitForPaymentComplete();
            }, 1000);
        }
    }

    async waitForSignupComplete() {
        const status = await api.getSignupStatus(this.signupId);
        if (status.creationStatus === 'completed') {
            this.signupCompleted = true;
        } else {
            setTimeout(() => {
                this.waitForSignupComplete();
            }, 5000);
        }
    }

    async savePassword() {
        if (this.password !== this.confirmPassword) {
            this.error = 'Passwords do not match';
            return;
        }

        const response = await api.authReset(this.passwordToken, this.password);
        console.log('save password result', response);
        if (response) {
            this.passwordSaved = true;
        } else {
            this.error = 'Failed to save password';
        }
    }
}
