
import { Options, Vue } from 'vue-class-component';
import AppTile from './AppTile.vue';
import api from '@/shared/api';
import getAppDefinitions, { AppDefinitions } from '@/shared/apps';
import session from "@/shared/session";

@Options({
    components: {
        AppTile,
    },
})
export default class AppStore extends Vue {
    protected installedApps = [];
    protected allApps: AppDefinitions = {};

    protected ready = false;

    get availableApps() {
        // only show apps that have available = true
        return Object.fromEntries(Object.entries(this.allApps).filter(([name, app]) => app.available));
    }

    async created() {
        this.allApps = await getAppDefinitions();

        const loggedIn = await session.checkSession();
        if (loggedIn) {
            const apps = await api.getInstalledApps();
            console.log('installedApps', apps);
            this.installedApps = apps;
        }

        this.ready = true;

        console.log('route', this.$route);
    }
}
