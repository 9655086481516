
import { Options, Vue } from 'vue-class-component';
import { Inject, Provide } from 'vue-property-decorator';
import { Flow } from '@/components/automation/flows/designer/Flow';
import DesignerViewModel from '@/components/automation/flows/designer/DesignerViewModel';
import { FlowObjectData, ObjectDefinition } from '@/components/automation/flows/definitions/FlowDefinitions';
import { Created } from '@/decorators/LifeCycle';
import LegacyModal from '@/components/common/LegacyModal.vue';

@Options({
    props: {},
    components: {
        Modal: LegacyModal,
    },
})
export default class FlowStatus extends Vue {
    @Inject() vm!: DesignerViewModel;
    ready = false;

    confirmSave = false;

    @Created
    init() {
        this.ready = true;
    }

    activate() {
        if (this.vm.flow.dirty) {
            this.confirmSave = true;
            return;
        }
        this.vm.flow.activate();
    }

    async saveAndActivate() {
        this.confirmSave = false;
        await this.vm.flow.save();
        await this.vm.flow.activate();
    }

    deactivate() {
        this.vm.flow.deactivate();
    }
}
