
import { Options, Vue } from "vue-class-component";
import LegacyModal from "@/components/common/LegacyModal.vue";
import api from "@/shared/api";
import session from "@/shared/session";

interface BusinessUnit {
    id: string;
    name: string;
    parent_id: string;
}

interface User {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    lastNamePrefix: string;
}

interface Role {
    id: any;
    name: any;
}

@Options({
  components: {
    Modal: LegacyModal,
  },
})

export default class AssignRole extends Vue {
  private userId ='';
  private roles: Role[] = [];
  private businessUnits: BusinessUnit[] = [];
  private businessUnitsLoaded = false;
  private selectedParentId = "";
  private newBusinessName ="";
  private selectedRole = "";
  private currentBu: BusinessUnit | undefined;
  private users: User[] = [];
  private isAssigned = false;
  private user_role : string | null = null;

  //Get Business Units :
  private async getCorrectBusinessUnits() {
        try {
            // Get all Business Units
            const businessUnits = await api.getBusinessUnits();
            this.businessUnits = businessUnits;
            this.businessUnitsLoaded = true;

            // Get all Roles
            const rolesData = await api.getRoles();
            this.roles = rolesData.map((role: { id: any; name: any; }) => ({ id: role.id, name: role.name }));

            // Get Current Business Unit
            this.currentBu = this.businessUnits.find((bu) => bu.id === session.getCurrentBusinessUnit());

            // Get all children Units
            const childBU = this.businessUnits.filter((bu) => bu.parent_id === this.currentBu?.id);
            
            // Get All
            this.businessUnits = this.currentBu ? [this.currentBu, ...childBU] : [...childBU];
            
            //Is Assigned ?
            this.user_role = await api.getRole(this.userId);
            
            
            if (this.user_role !== null) {
              this.isAssigned = true;
            }
        } catch (error) {
            console.error('Error loading business units:', error);
        }
  }
  //Create

  private async CreateRole(): Promise<void> {
    // Check if all required fields are filled
    if (!this.selectedRole) {
      console.error("A role is required.");
      return;
    }
    if (!this.newBusinessName) {
      console.error("Business Unit is required.");
      return;
    }
    if (!this.userId) {
      console.error('No user ID provided');
      return;
    }

    try {
      // Find the selected role by its name and get its id
      const roleId = this.roles.find((role) => role.id === this.selectedRole)?.id;

      // Update user's role with the retrieved roleId
      await api.AssignRole(this.userId, roleId, this.newBusinessName);
      this.$router.push({name: 'manage-users'});
              
    } catch (error) {
      console.error("Error in assigning roles:", error);
    }
  }

  private async UpdateRole(): Promise<void> {
    // Check if all required fields are filled
    if (!this.selectedRole) {
      console.error("A role is required.");
      return;
    }
    if (!this.newBusinessName) {
      console.error("Business Unit is required.");
      return;
    }
    if (!this.userId) {
      console.error('No user ID provided');
      return;
    }

    try {
      // Find the selected role by its name and get its id
      const roleId = this.roles.find((role) => role.id === this.selectedRole)?.id;

      // Update user's role with the retrieved roleId
      await api.updateRole(this.userId, roleId, this.newBusinessName);
      this.$router.push({name: 'manage-users'});
              
    } catch (error) {
      console.error("Error in assigning roles:", error);
    }
  }


  cancelAssign(): void  {
    this.$router.push({name: 'manage-users'});
  }

  mounted(): void{
    if (this.$route && this.$route.query) {
      this.userId = this.$route.query.userId as unknown as string;
    }
    this.getCorrectBusinessUnits();
  }
}
