
import { Options, Vue } from 'vue-class-component';
// import {Validate, ValidationRuleSet} from "@/decorators/Validation";
// import {required} from '../../validation/rules';
// import Validation from "@/components/Shared/Validation/Validation";
import { Inject, InjectReactive } from 'vue-property-decorator';
import { ListViewModel } from '@/components/content/ListViewModel';
import LegacyModal from '@/components/common/LegacyModal.vue';
import {Content} from "@/components/content/Content";

@Options({
    components: {
        Modal: LegacyModal,
    },
})
export default class AddContent extends Vue /*TODO: VueExtend(Validation)*/ {
    @Inject() listVM!: ListViewModel;

    private name: string = '';
    private type: string = '';

    // @ValidationRuleSet()
    // validation = {
    //     'name': [required]
    // }

    onClose() {
        this.$router.push({ name: 'content' });
    }

    // @Validate('validation')
    async onConfirm() {
        let id = await this.listVM.add(new Content({ name: this.name, type: this.type }));
        await this.$router.push({ name: 'content-edit', params: { contentId: id } });
    }
}
