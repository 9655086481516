
import { Options, Vue } from 'vue-class-component';
import LegacyModal from '@/components/common/LegacyModal.vue';
import api from '@/shared/api';

@Options({
    components: {
        Modal: LegacyModal,
    },
    props: {},
})
export default class ExternalSystemsAddMicrosoftAzure extends Vue {
    protected ready = false;
    protected client_id = '';
    protected client_secret = '';

    async created() {
        const id = this.$route.params.newOrId as string;
        if (id === 'callback') {
            console.log('code');
            debugger;
            const state = this.$route.query.state;
            if (state) {
                const stateParts = state.toString().split('-');
                const env = stateParts[0];
                const id = stateParts.slice(2).join('-');

                if (env === (window.location.port !== '443' ? 'development' : 'prod')) {
                    if (id === 'new') {
                        await api.addExternalSystem('microsoftazure', '', this.$route.query);
                    } else {
                        await api.updateExternalSystem(id, 'microsoftazure', '', this.$route.query);
                    }

                    this.$router.push({ name: 'external-systems' });
                }
            }
        } else if (id !== 'new') {
            const data = await api.getExternalSystem(id);
            this.client_id = data.params.client_id;
            this.client_secret = data.params.client_secret;
        }
        this.ready = true;
    }

    async saveAndRedirect() {
        let id = this.$route.params.newOrId as string;
        if (id === 'new') {
            const data = await api.addExternalSystem('microsoftazure', '', {
                client_id: this.client_id,
                client_secret: this.client_secret,
            });

            id = data.id;
        } else {
            await api.updateExternalSystem(id, 'microsoftazure', '', {
                client_id: this.client_id,
                client_secret: this.client_secret,
            });
        }

        const appStoreEnv = window.location.port !== '443' ? 'development' : 'prod';
        const state = appStoreEnv + '-extsystem-' + id;
        const redirect_uri = encodeURIComponent('https://raffish.app/external-systems-callback/microsoftazure');
        window.location.href = 'https://login.microsoftonline.com/common/adminconsent?client_id=' + this.client_id + '&state=' + state + '&redirect_uri=' + redirect_uri;
    }
}
