
import { Options, Vue } from 'vue-class-component';
import AppTile from './AppTile.vue';
import {Prop, Watch} from 'vue-property-decorator';
import api from '@/shared/api';
import { mergeDeep } from '@/shared/mergedeep';
import readXlsxFile from 'read-excel-file';
import LegacyModal from "@/components/common/LegacyModal.vue";
import Debounce from "@/decorators/Debounce";
import {Created, Mounted} from "@/decorators/LifeCycle";

type Column = {
    name: string;
    value?: string;
    createCustomFieldValues?: boolean;
};

type RuleSet = {
    name: string;
    clientId: string;
    columns: { [columnName: string]: Column };
    filetypes: { [key: string]: boolean };
};

@Options({
    components: {
        Modal: LegacyModal,
        AppTile,
    },
})
export default class AssetUnzipSettings extends Vue {
    @Prop()
    protected app: undefined | any = undefined;

    protected ready = false;
    protected tab: 'extract' | 'rulesets' = 'extract';

    protected testMode = false;
    protected testModeFields = [
        { label: '{{ filepath }}', name: 'filepath', value: 'folder1/folder2/test.jpg', type: 'text' },
        { label: '{{ zip_filename }}', name: 'zip_filename', value: '', type: 'text' },
        { label: '{{ zip_campaign }}', name: 'zip_campaign', value: '', type: 'text' },
        { label: '{{ zip_campaign_start }}', name: 'zip_campaign_start', value: '', type: 'datetime-local' },
        { label: '{{ zip_campaign_end }}', name: 'zip_campaign_end', value: '', type: 'datetime-local' },
        { label: '{{ zip_tags }}', name: 'zip_tags', value: '', type: 'text' },
        { label: '{{ mediapath }}', name: 'mediapath', value: (filePath: string) => { return 'https://raffish.app/......'; }, type: 'readonly' },
        { label: '{{ filename }}', name: 'filename', value: (filePath: string) => { return filePath.replace(/^.+\/([^/]+)$/, '$1'); }, type: 'readonly' },
        { label: '{{ basename }}', name: 'basename', value: (filePath: string) => { return filePath.replace(/^.+\/([^/]+)$/, '$1').replace(/\.([^.]+)$/, ''); }, type: 'readonly' },
        { label: '{{ fileextension }}', name: 'fileextension', value: (filePath: string) => { return filePath.replace(/^.+\.([^.]+)$/, '$1').toLowerCase(); }, type: 'readonly' },
        { label: '{{ filesize }}', name: 'filesize', value: '100', type: 'text' },
    ];
    protected testResults: { [key: string]: string } = {};

    protected rulesets: RuleSet[] = [
        // { name: 'ZIP regels', columns: [] }
    ];

    protected filetypesList = [
        {
            name: 'images',
            label: 'Images',
            extensions: ['png', 'gif', 'jpg', 'jpeg', 'webp', 'tiff', 'raw', 'bmp', 'heif', 'svg', 'ai', 'aps'],
        },
        {
            name: 'presentation',
            label: 'Presentation',
            extensions: ['ppt', 'pptx', 'pptm', 'potx', 'potm', 'ppsx', 'ppsm', 'odp'],
        },
        {
            name: 'video',
            label: 'Video',
            extensions: ['mp4', 'mov', 'wmv', 'avi', 'avchd', 'flv', 'f4v', 'mkv', 'webm'],
        },
        { name: 'audio', label: 'Audio', extensions: ['mp3', 'aac', 'ogg', 'flac', 'wav', 'aiff'] },
        { name: 'flash', label: 'Flash', extensions: ['swf'] },
        { name: 'html', label: 'Html', extensions: ['html', 'htm'] },
        { name: 'documents', label: 'Documents', extensions: ['doc', 'docx', 'rtf', 'txt', 'odt', 'xps', 'pdf'] },
        { name: 'spreadsheet', label: 'Spreadsheets', extensions: ['xls', 'xlsx', 'xlt', 'xltx', 'ods', 'csv', 'tab'] },
        { name: 'archives', label: 'Archives', extensions: ['zip'] },
        { name: 'fonts', label: 'Fonts', extensions: ['ttf', 'woff'] },
    ];

    protected addRuleSet: boolean = false;
    protected newRuleSetName: string = '';
    protected newRuleSetClientId: string = '';
    protected editRuleSet: false | RuleSet = false;
    protected extractAssetId = '';
    protected extractRuleSet = '';
    protected extractAssetDone = false;
    protected uploadCsvDone = false;
    protected resetImportDone = false;
    protected working = false;

    protected recentZipAssets = [];
    protected activeImports = {};

    protected clients: { [clientId: string]: { [key: string]: any } } = {};
    protected error = '';

    async created() {
        try {
            let clients = await api.getSprinklrClients(this.app.id);
            for (let client of clients) {
                this.clients[client.clientId] = client;
            }
        } catch (e) {
            this.error = 'Error while retrieving Sprinklr Clients';
            return;
        }

        for (const clientId in this.clients) {
            const client = this.clients[clientId];
            try {
                client.auth = true;
                client.customProps = await api.getSprinklrCustomProperties(this.app.id, client.clientId);
                console.log('client.customProps', client.customProps);
            } catch (e) {
                console.error(e);
                client.auth = false;
                client.customProps = [];
                continue;
            }
            try {
                client.campaigns = {};
                client.campaignsByName = {};

                const campaigns = await api.getSprinklrCampaigns(this.app.id, client.clientId);
                const subCampaigns = [];

                for (let i = 0; i < campaigns.length; i++) {
                    if (campaigns[i].archived || campaigns[i].deleted) continue;

                    if (campaigns[i].parentCampaignId) {
                        subCampaigns.push(campaigns[i]);
                    } else {
                        client.campaigns[campaigns[i].id] = { name: campaigns[i].name, subCampaigns: [] };
                    }
                }

                for (let i = 0; i < subCampaigns.length; i++) {
                    let subCampaign = subCampaigns[i];
                    if (subCampaign) {
                        let campaign = client.campaigns[subCampaign.parentCampaignId];
                        if (campaign) {
                            campaign.subCampaigns.push({ name: subCampaign.name });
                        }
                    }
                }

                for (let i in client.campaigns) {
                    let campaign = client.campaigns[i];
                    if (campaign) {
                        client.campaignsByName[campaign.name] = i;
                    }
                }
            } catch (e) {
                console.error(e);
                client.campaigns = {};
                client.campaignsByName = {};
            }
        }

        await this.refreshRecentFiles();

        if (this.app.settings && this.app.settings.rulesets) {
            this.rulesets = this.app.settings && this.app.settings.rulesets;
        }

        // this.rulesets = mergeDeep(this.rulesets, this.app.settings && this.app.settings.rulesets ? this.app.settings.rulesets : []);

        this.ready = true;
    }

    public formatBytes (size: number) {
        // format bytes to KB, MB, GB, TB, PB, EB, ZB, YB
        const i = Math.floor(Math.log(size) / Math.log(1024));
        return (
            parseFloat((size / Math.pow(1024, i)).toFixed(2)) +
        ' ' +
        ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][i]
        );
    }

    public async refreshRecentFiles() {
        this.recentZipAssets = await api.getSprinklrRecentZipAssets(this.app.id);
        this.activeImports = await api.getAssetUnzipActiveImports();
    }

    public getSettings() {
        return mergeDeep(this.app.settings ?? {}, { rulesets: this.rulesets });
    }

    public closeRuleSet() {
        this.$emit('save');
        this.editRuleSet = false
    }

    public readSampleFile() {
        if (this.editRuleSet) {
            const sampleFiles = (this.$refs.sampleFile as HTMLInputElement).files;
            if (sampleFiles && sampleFiles.length > 0) {
                const sampleFile = sampleFiles[0];
                readXlsxFile(sampleFile).then((rows) => {
                    if (this.editRuleSet && rows && rows.length > 0) {
                        const columns = rows[0];
                        for (let i = 0; i < columns.length; i++) {
                            const column = columns[i];
                            const name = column.toString();
                            if (this.editRuleSet.columns[name]) {
                                this.editRuleSet.columns[name].name = name;
                            } else {
                                this.editRuleSet.columns[name] = { name: name };
                            }
                        }
                        for (const name in this.editRuleSet.columns) {
                            if (this.editRuleSet.columns[name]) {
                                const column = this.editRuleSet.columns[name];
                                if (columns.indexOf(column.name) === -1) {
                                    delete this.editRuleSet.columns[name];
                                }
                            }
                        }
                    }
                    // TODO: Fix order. Remove columns.
                    console.log('this.editRuleSet', this.editRuleSet);
                });
            }
        }
    }

    public getColumnByName(name: string): Column | null {
        if (this.editRuleSet) {
            if (this.editRuleSet.columns[name]) {
                return this.editRuleSet.columns[name];
            }
        }
        return null;
    }

    public async extractAsset() {
        let data = this.extractAssetId.split(',');
        await api.postAssetUnzipExtractZipAsset(this.app.id, data[0], data[1], this.extractRuleSet);
        this.working = true;

        setTimeout(async () => {
            await this.refreshRecentFiles();
            this.working = false;
            this.extractAssetDone = true;
        }, 5000);
    }

    public async uploadCsv(assetId: string, clientId: string) {
        await api.postAssetUnzipUploadCsv(this.app.id, assetId, clientId);
        this.uploadCsvDone = true;
    }

    public async resetImport(assetId: string, clientId: string) {
        await api.postAssetUnzipResetImport(this.app.id, assetId, clientId);
        this.working = true;

        setTimeout(async () => {
            await this.refreshRecentFiles();
            this.working = false;
            this.resetImportDone = true;
        }, 5000);
    }

    public customFieldOptions(customFieldLabel: string) {
        let options: { label: string; value: string }[] = [];
        if (this.editRuleSet) {
            let clientId = this.editRuleSet.clientId;
            let client = this.clients[clientId];

            for (let i = 0; i < client.customProps.length; i++) {
                let customProp = client.customProps[i];
                if (customProp.label && customProp.label.en === customFieldLabel) {
                    return customProp.options2;
                }
            }
        }
        return options;
    }

    public get campaignFieldOptions() {
        let options: { label: string; value: string }[] = [];
        if (this.editRuleSet) {
            let clientId = this.editRuleSet.clientId;
            let client = this.clients[clientId];

            for (let i in client.campaigns) {
                options.push({
                    label: client.campaigns[i].name,
                    value: client.campaigns[i].name,
                });
            }
        }
        options.sort((a, b) => {
            return a.label.localeCompare(b.label);
        });
        return options;
    }

    public get subCampaignFieldOptions() {
        let options: { label: string; value: string }[] = [];
        if (this.editRuleSet) {
            let clientId = this.editRuleSet.clientId;
            let client = this.clients[clientId];
            let campaignNamesColumn = this.getColumnByName('Campaigns');
            if (campaignNamesColumn && campaignNamesColumn.value) {
                const campaignNames = campaignNamesColumn.value.split(',');
                console.log('campaignNames', campaignNames);
                for (let i = 0; i < campaignNames.length; i++) {
                    const campaignName = campaignNames[i].trim();
                    const campaignId = client.campaignsByName[campaignName];
                    console.log('campaignId', campaignId);

                    if (campaignId) {
                        const campaign = client.campaigns[campaignId];
                        if (campaign) {
                            console.log('campaign', campaign, campaign.subCampaigns);
                            for (let i in campaign.subCampaigns) {
                                options.push({
                                    label: campaign.subCampaigns[i].name,
                                    value: campaign.subCampaigns[i].name,
                                });
                            }
                        }
                    }
                }
            }
        }
        options.sort((a, b) => {
            return a.label.localeCompare(b.label);
        });
        return options;
    }

    public customFieldRequired(customFieldLabel: string) {
        if (this.editRuleSet) {
            let clientId = this.editRuleSet.clientId;
            let client = this.clients[clientId];

            for (let i = 0; i < client.customProps.length; i++) {
                let customProp = client.customProps[i];
                if (customProp.label && customProp.label.en === customFieldLabel && customProp.assetLevelConfig) {
                    for (let j = 0; j < customProp.assetLevelConfig.length; j++) {
                        if (
                            customProp.assetLevelConfig[j].assetClasses &&
                            customProp.assetLevelConfig[j].assetClasses.indexOf('MEDIA_ASSET') !== -1 &&
                            customProp.assetLevelConfig[j].required
                        ) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    public onAddRuleSet() {
        this.addRuleSet = true;
    }

    public doAddRuleSet() {
        this.rulesets.push({
            name: this.newRuleSetName,
            clientId: this.newRuleSetClientId,
            columns: {},
            filetypes: {}
        });
        this.addRuleSet = false;
    }

    @Mounted
    loadFromLocalStorage() {
        const testModeFields = localStorage.getItem('assetUnzip-testModeFields');
        if (testModeFields) {
            const fieldValues = JSON.parse(testModeFields);
            for (let i = 0; i < this.testModeFields.length; i++) {
                let field = this.testModeFields[i];
                if (field.type !== 'readonly' && fieldValues[field.name]) {
                    field.value = fieldValues[field.name];
                }
            }
        }
    }

    @Watch('testModeFields', { deep: true })
    saveInLocalStorage() {
        if (this.testMode) {
            const fields: {[key: string]: string} = {};
            for (let i = 0; i < this.testModeFields.length; i++) {
                let field = this.testModeFields[i];

                if (field.type !== 'readonly' && typeof field.value === 'string') {
                    fields[field.name] = field.value;
                }
            }
            localStorage.setItem('assetUnzip-testModeFields', JSON.stringify(fields));
        }
    }

    @Watch('testModeFields', { deep: true })
    @Watch('editRuleSet.columns', { deep: true })
    @Watch('testMode')
    @Debounce(500)
    public async runTest() {
        if (this.testMode && this.editRuleSet) {
            const customProps: {[key: string]: {[key: string]: string}} = {};
            const testData: {[key: string]: any} = {};
            const columns: {[key: string]: any} = {};
            for (let i = 0; i < this.testModeFields.length; i++) {
                let field = this.testModeFields[i];

                if (field.type !== 'readonly') {
                    testData[field.name] = field.value;
                } else if (field.type === 'readonly' && typeof field.value === 'function') {
                    testData[field.name] = field.value(this.testModeFields[0].value as unknown as string);
                }
            }
            for (let i in this.editRuleSet.columns) {
                columns[i] = this.editRuleSet.columns[i].value;

                if (i.indexOf('Partner CF:') === 0) {
                    let customFieldLabel = i.substring(11);
                    let customFieldOptions = this.customFieldOptions(customFieldLabel);
                    customProps[customFieldLabel] = {};

                    for (let j = 0; j < customFieldOptions.length; j++) {
                        let option = customFieldOptions[j];
                        customProps[customFieldLabel][option.label] = option.label;
                    }
                }
            }

            this.testResults = await api.postAssetUnzipTest(testData, columns, customProps);

        } else {
            this.testResults = {};
        }
    }
}
