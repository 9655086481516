
import { Options, Vue } from 'vue-class-component';
import LegacyModal from "@/components/common/LegacyModal.vue";
import api from "@/shared/api";

type Env = {
    name: string;
    key: string;
    redirect_uri: string;
};

@Options({
    components: {Modal: LegacyModal}
})
export default class PurchaseRegisterSprinklrCallback extends Vue {
    protected ready = false;
    protected error = '';
    protected name = '';
    protected email = '';
    protected businessUnitName = '';

    async created() {
        const state = this.$route.query.state;
        if (state) {
            const stateParts = state.toString().split('-');
            const env = stateParts[0];

            if (env === (window.location.port !== '443' && window.location.port !== '' ? 'development' : 'prod')) {

                const response = await api.saveSignupFromSprinklr({
                    env: this.$route.query.env as string,
                    code: this.$route.query.code as string,
                    state: state as string,
                });
                console.log('register result', response);
                if (response.error) {
                    this.error = response.error;
                } else {
                    this.businessUnitName = response.businessUnitName;
                    this.email = response.sprinklrUser.email;
                    this.name = response.sprinklrUser.name;
                    // this.$router.push({ name: 'external-systems' });

                    // Redirect to the payment portal
                    // @TODO: Redirect to the payment portal instead of the callback directly
                    // Make sure to pass response.signupId

                    const payResponse = await api.molliePay({
                        name: this.name,
                        email: this.email,
                        signupId: response.signupId,
                    });
                    console.log('pay result', payResponse);

                    if (payResponse.url) {
                        window.location.href = payResponse.url;
                    }
                }
            }
        }
        this.ready = true;
    }
}
