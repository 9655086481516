import { reactive } from 'vue';
import api from '@/shared/api';

export type AppDataInstall = {
    route: string;
    params: { [key: string]: any };
};

export type AppDataKey = {
    key: string;
};

export type AppDefinition = {
    title: string;
    icon: string;
    short_description: string;
    description: string;
    install: AppDataInstall;
    redirect_uri: string;
    api_keys: {
        [env: string]: AppDataKey;
    };
    available: boolean;
    pricing?: {
        price: number;
        currency: 'EUR';
        interval: 'month';
    };
};

export type AppDefinitions = { [key: string]: AppDefinition };

let appsDataLoaded = false;
let appsData: AppDefinitions = {
};

export default async function getAppDefinitions(): Promise<AppDefinitions> {
    if (!appsDataLoaded) {
        appsData = await api.getAppDefinitions();
        appsDataLoaded = true;
    }
    return appsData;
}
